<template>
  <div class="content herb flex flex-col gap-4">
    <div>
      <router-link to="/plants" class="btn btn-primary btn-sm">
        <span>Back to Plants</span>
        </router-link>
    </div>

    <h2 class="text-xl font-bold">{{ herb.Name }}</h2>

    <button-select
      v-model="page"
      :options="pages"
      :disabled="isNaN(herbid)"
      @input="onPageSelect"
    />

    <herb-edit
      v-if="page==='Edit'"
      :herbid="herbid"
      @herbid="onHerbId"
      @updated="onUpdate"
    />

    <herb-ideal
      v-if="page==='Ideals'"
      :herbid="herbid"
      @updated="onUpdate"
    />

    <herb-positions
      v-if="page==='Positions'"
      :herbid="herbid"
      @updated="onUpdate"
    />

    <h3 v-if="page===null">Select a category</h3>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/ButtonSelect.vue');
const HerbEdit = () => import('@/components/herb/HerbEdit.vue');
const HerbIdeal = () => import('@/components/herb/HerbIdeal.vue');
const HerbPositions = () => import('@/components/herb/HerbPositions.vue');

export default {
  name: 'Herb',
  components: {
    ButtonSelect,
    HerbEdit,
    HerbIdeal,
    HerbPositions,
  },
  computed: {
    herbid() {
      return parseInt(this.$route.params.herbid, 10) ?? null;
    },
  },
  data() {
    return {
      loadingCount: 0,
      herb: {
        Id: null,
        GroupId: null,
        Name: null,
      },
      page: this.$route.query.page ?? 'Edit',
      pages: [
        { text: '1. Plant details', value: 'Edit' },
        { text: '2. Production process', value: 'Ideals' },
        { text: '3. Allowed positions', value: 'Positions' },
      ],
    };
  },
  methods: {
    fetchHerb() {
      if (!this.herbid) {
        return;
      }
      const params = {
        herbids: [this.herbid],
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'herbs' })
        .send({ params })
        .then((res) => {
          const [herb] = res.body;
          this.herb = herb;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchHerb();
    },
    onHerbId(herbid) {
      this.$router.push({ name: 'Herb', params: { herbid } });
    },
    onUpdate() {
      if (this.page === 'Edit') {
        this.setPage('Ideals');
      } else if (this.page === 'Ideals') {
        this.setPage('Positions');
      } else {
        this.setPage(null);
      }
      this.$nextTick(() => {
        this.fetchHerb();
      });
    },
    setPage(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
    },
    onPageSelect(page) {
      this.setPage(page);
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
  .herb {
    max-width: 900px;
  }
</style>
